<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiHeadlineProps {
  headline?: string;
}
const props = withDefaults(defineProps<CiHeadlineProps>(), {
  headline: '',
  type: 'main',
});

/********************
 * COMPOSITIONS      *
 ********************/
const contentStore = useContentStore();
const { dynamicHeadline } = useDynamicHeadline();

/********************
 * REFS & VARS       *
 ********************/
const computedHeadline = computed(() => {
  if (props.headline) {
    return props.headline;
  }

  if (contentStore.h1) {
    return contentStore.h1;
  }

  if (dynamicHeadline.value) {
    return dynamicHeadline.value;
  }

  return '';
});
</script>

<template>
  <section
    v-if="computedHeadline"
    class="bg-gray-10"
  >
    <div class="container relative">
      <h1
        class="m-0 text-center"
        v-html="computedHeadline"
      />
    </div>
  </section>
</template>

<style></style>
